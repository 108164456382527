<!--
 * @Descripttion: 
 * @version: 
 * @Author: Hou Jiechao
 * @Date: 2021-08-02 09:30:46
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-03-16 10:30:06
 * @FilePath: \工作\storehouse\src\components\factory_boxwash.vue
-->
<template>
  <dv-full-screen-container class="bj" style="width: 100%; height: 100%">
    <dv-border-box-8 style="background: rgba(22, 40, 80, 0.8)">
      <div v-if="show" class="boxdiv">
        <dv-border-box-7 :color="['#3D5AA1']" class="popup">
          <div>
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div style="color: #40c5ff">收污点数</div>
            </div>
            <div style="padding-top: 20px; font-size: 16px; color: #40c5ff">
              读取布草原归属
              <span v-for="(item, i) in hotels" :key="i">
                {{ item.name }}{{ item.count }}
              </span>
              请选择新归属：
            </div>
            <div class="height">
              <van-cell-group v-for="(e, i) in customer_list" :key="e">
                <van-cell @click="sense(e, i)">
                  <template #title>
                    <div
                      style="
                        text-align: left;
                        padding-left: 20px;
                        color: #40c5ff;
                      "
                    >
                      {{ e.hotel_name }}
                    </div>
                  </template>
                  <template #default>
                    <div
                      style="
                        padding-right: 20px;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <van-radio
                        :name="i"
                        v-model="hotel_count"
                        shape="square"
                      ></van-radio>
                    </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>

            <div class="dialog_button">
              <dv-border-box-12 class="dialog_button_but" @click="zero"
                >取消</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="submit"
                >确定</dv-border-box-12
              >
            </div>
          </div>
        </dv-border-box-7>
      </div>

      <div v-if="showorder" class="boxdiv">
        <dv-border-box-7 :color="['#3D5AA1']" class="popup">
          <div>
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div style="color: #40c5ff">收污点数</div>
            </div>
            <div style="padding-top: 20px; font-size: 16px; color: #40c5ff">
              请选择{{ order_leaser_list[0].scene_name }}订单：
            </div>
            <div class="height">
              <van-cell-group v-for="(e, i) in order_leaser_list" :key="e">
                <van-cell @click="senses(e, i)">
                  <template #title>
                    <div>
                      <div
                        style="
                          text-align: left;
                          padding-left: 20px;
                          color: #40c5ff;
                        "
                      >
                        {{ e.minute }} {{ e.leaser_order_count }}件
                      </div>
                      <div
                        style="
                          text-align: left;
                          padding-left: 20px;
                          color: #1293f6;
                        "
                      >
                        {{ e.code }}
                      </div>
                      <div></div>
                    </div>
                  </template>
                  <template #default>
                    <div
                      style="
                        padding-right: 20px;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <van-radio
                        :name="i"
                        v-model="order_count"
                        shape="square"
                      ></van-radio>
                    </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>

            <div class="dialog_button">
              <dv-border-box-12 class="dialog_button_but" @click="zero"
                >取消</dv-border-box-12
              >
              <dv-border-box-12
                class="dialog_button_but"
                @click="jump"
                v-if="bucao_type == 'lease'"
                >跳过</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="submits"
                >确定</dv-border-box-12
              >
            </div>
          </div>
        </dv-border-box-7>
      </div>

      <div v-if="ownshow" class="boxdiv">
        <dv-border-box-7 :color="['#3D5AA1']" class="popup">
          <div>
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div style="color: #40c5ff">收污点数</div>
            </div>
            <div style="padding-top: 20px; font-size: 16px; color: #40c5ff">
              请确认布草归属酒店
            </div>
            <div style="height: 100px">
              <div style="font-size: 30px; color: #40c5ff; padding: 15px 0">
                {{ ownhotel.name }}
              </div>
            </div>
            <div class="dialog_button">
              <dv-border-box-12 class="dialog_button_but" @click="owncancel"
                >取消</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="ownok"
                >确定</dv-border-box-12
              >
            </div>
          </div>
        </dv-border-box-7>
      </div>
      <van-row type="flex" v-if="!login" style="height: 100%; padding: 10px">
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img
                :src="device?.bandb?.avatar"
                class="logo"
                v-if="device?.bandb?.avatar"
              />
              <img
                src="../assets/slogan.png"
                class="logos"
                v-if="!device.bandb?.avatar"
              />
              <div>卫生联防联控云平台</div>
            </div>
            <div style="display: flex; margin-top: 20px; align-items: flex-end">
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                【{{ staff_info?.role }}】
              </div>
            </div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtext">芯任收污点数</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device.device?.model">
              设备型号：{{ device.device?.model }}
            </div>
            <div v-if="device.device?.id">
              设备编号：{{ device.device?.id }}
            </div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col
          span="24"
          style="
            height: 30%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          "
        >
          <!-- <div>
            <img :src="qrcode" alt="" class="qrcode" />
            <div style="font-size: 20px; color: #fff">无人交接</div>
          </div> -->
          <div>
            <dv-decoration-9
              style="width: 300px; height: 300px; margin: 0 100px; color: #fff"
              >{{ status_msg }}</dv-decoration-9
            >
          </div>
          <!-- <div>
            <img class="qrcode" src="../assets/saomiao.png" v-if="thumb" />
            <div style="font-size: 20px; color: #fff">扫描读单</div>
          </div> -->
        </van-col>
        <van-col span="24" style="height: 40%">
          <div class="preview">
            <div style="font-size: 24px; margin-bottom: 20px">
              <!-- <van-icon name="success" color="#1ED76D" v-if="status" /><van-icon
                name="cross"
                color="red"
                v-if="!status"
              /> -->
            </div>
            <div class="status">
              <div
                class="status_msg"
                v-for="(item, index) in status_msgs"
                :key="index"
              >
                <van-icon
                  name="success"
                  color="#1ED76D"
                  v-if="item.status"
                /><van-icon
                  name="cross"
                  color="red"
                  v-if="!item.status && !item.wran"
                />
                <van-icon
                  name="warn-o"
                  color="yellow"
                  v-if="item.wran && !item.status"
                />{{ item.msg }}{{ item.status ? "成功" : "失败" }}
              </div>
            </div>
          </div>
          <van-button
            class="buttonS"
            type="success"
            :loading="loading"
            loading-type="spinner"
            @click="resetdevice"
            >重置读卡
          </van-button>
          <van-button
            class="button"
            type="success"
            @click="restarts"
            v-if="!status"
            >重启</van-button
          >
        </van-col>
      </van-row>
      <van-row
        v-if="login == 'hotel_count' || 'factory_count'"
        type="flex"
        style="height: 100%; padding: 10px"
      >
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img src="../assets/slogan.png" class="logos" />
              <div>卫生联防联控云平台</div>
            </div>
            <div style="display: flex; margin-top: 20px" v-if="staff_info">
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 60px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 60px">
                【{{ staff_info?.role }}】
              </div>
            </div>
            <div class="infotime">{{ order.time }}</div>
            <div class="infotime" v-if="service">批次号：{{ service }}</div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任收污点数</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div>设备型号：{{ device.device?.model }}</div>
            <div>设备编号：{{ device.device?.id }}</div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col span="8" style="height: 85%">
          <dv-decoration-9 class="zero" :dur="dur" :style="color">
            <countTo
              :startVal="uncount || 0"
              :endVal="msg?.count || 0"
              :duration="3000"
            ></countTo
          ></dv-decoration-9>
          <div class="dialog_button">
            <dv-border-box-12 class="dialog_button_but" @click="stress"
              >重读</dv-border-box-12
            >
            <dv-border-box-12
              class="dialog_button_but"
              @click="pause"
              v-if="!pauses"
              >暂停</dv-border-box-12
            >
            <dv-border-box-12
              class="dialog_button_but"
              @click="goon"
              v-if="pauses"
              >继续</dv-border-box-12
            >
            <dv-border-box-12 class="dialog_button_but" @click="zero"
              >归零</dv-border-box-12
            >
          </div>
          <div
            v-if="msg.unknown_bucao_count > 0"
            style="color: red; font-size: 32px"
          >
            当前未知芯片数量：{{ msg.unknown_bucao_count }}
          </div>
        </van-col>
        <van-col span="11" style="height: 85%">
          <dv-border-box-13 class="btn">
            <div class="border" :style="{ height: listheight }">
              <div
                class="app-card-content"
                v-if="bucao_summary && bucao_summary.length < 1"
              >
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">被套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">床单</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">枕套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">浴巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">面巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">地巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div
                class="app-card-content"
                v-if="bucao_summary && bucao_summary.length > 0"
              >
                <div
                  v-for="(item, index) in bucao_summary"
                  :key="index"
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item', getBorderClass(index)]"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box" :style="item.color">
                        {{ item?.count ? item?.count : "0" }}
                      </div>
                      <div class="box_name">{{ item.name }}</div>
                      <div class="box_name_size">{{ item.size }}</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
            </div>
          </dv-border-box-13>
        </van-col>
        <van-col span="5" style="height: 85%">
          <dv-border-box-6
            :color="['#3E6BAF', '#30EAF9']"
            style="padding: 10px 0px 0 10px"
          >
            <div class="order_title">订单详情</div>
            <van-collapse v-model="activeNames" accordion class="collapse">
              <van-collapse-item
                :name="index"
                v-for="(item, index) in order"
                :key="index"
              >
                <template #title>
                  <div>
                    <div
                      style="
                        color: #fff;
                        font-size: 16px;
                        text-align: left;
                        line-height: 30px;
                      "
                    >
                      订单号:{{ item.code }}
                    </div>
                    <div
                      style="
                        color: #fff;
                        font-size: 16px;
                        text-align: left;
                        line-height: 30px;
                      "
                    >
                      下单人员:{{ item.create_staff.name }}
                    </div>
                  </div>
                </template>
                <template #value>
                  <div>
                    <div
                      style="color: #fff; font-size: 16px; line-height: 30px"
                    >
                      <!-- {{ item.minute }} -->
                    </div>
                    <div
                      style="color: #fff; font-size: 16px; line-height: 30px"
                    >
                      {{ item.minute }}
                      <!-- 数量:{{ item?.count }} -->
                    </div>
                  </div>
                </template>
                <div class="order_center" v-if="item.code">
                  <div class="center_title" v-if="item.recv_staff">
                    <div class="title_order">送单详情</div>
                    <div class="title_conter">
                      <div class="title_text">
                        <div>送单人员：</div>
                        <div style="font-size: 12px">
                          {{ item.recv_staff?.name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>送单电话：</div>
                        <div style="font-size: 12px">
                          {{ item?.recv_staff?.phone }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>送单时间：</div>
                        <div style="font-size: 12px">
                          {{ item?.recv_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <dv-decoration-2
                    style="width: 450px; height: 5px"
                    v-if="item.recv_staff"
                  />
                  <div class="center_title" v-if="item?.answer_staff">
                    <div class="title_order">应单详情</div>
                    <div class="title_conter">
                      <div class="title_text">
                        <div>应单人员：</div>
                        <div style="font-size: 12px">
                          {{ item?.answer_staff.name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>应单电话：</div>
                        <div style="font-size: 12px">
                          {{ item?.answer_staff.phone }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>应单时间：</div>
                        <div style="font-size: 12px">
                          {{ item?.answer_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <dv-decoration-2
                    style="width: 450px; height: 5px"
                    v-if="item?.answer_staff"
                  />
                  <div class="center_title" v-if="item?.create_staff">
                    <div class="title_order">下单详情</div>
                    <div class="title_conter">
                      <div class="title_text">
                        <div>下单人员：</div>
                        <div style="font-size: 12px">
                          {{ item?.create_staff?.name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>下单电话：</div>
                        <div style="font-size: 12px">
                          {{ item?.create_staff.phone }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>下单时间：</div>
                        <div style="font-size: 12px">{{ item?.time }}</div>
                      </div>
                      <div class="title_text">
                        <div>下单公司：</div>
                        <div style="font-size: 12px">
                          {{ item?.hotel_name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>打印情况：</div>
                        <div style="font-size: 12px">
                          {{ item.print_info ? item.print_info : "不打印" }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>收污地址：</div>
                        <div style="font-size: 12px">
                          {{ item.get_address }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-collapse-item>
              <div v-if="login == 'hotel_count' || login == 'factory_count'">
                <div class="title_order">下单详情</div>
                <div class="title_conter">
                  <div class="title_text">
                    <div>订单编号：</div>
                    <div style="font-size: 12px">
                      {{ order_allinfo.code }}
                    </div>
                  </div>
                  <div class="title_text">
                    <div>下单人员：</div>
                    <div style="font-size: 12px">
                      {{ order_allinfo.staff_name }}
                    </div>
                  </div>
                  <div class="title_text">
                    <div>下单时间：</div>
                    <div style="font-size: 12px">
                      {{ order_allinfo.time }}
                    </div>
                  </div>
                  <div class="title_text">
                    <div>酒店:</div>
                    <div style="font-size: 12px">
                      {{ order_allinfo.scene_name }}
                    </div>
                  </div>
                  <!-- <div class="title_text">
                  <div>酒店联系人:</div>
                  <div style="font-size: 12px">
                    {{ order_allinfo.hotel.contact }}
                  </div>
                </div>
                <div class="title_text">
                  <div>酒店联系人电话:</div>
                  <div style="font-size: 12px">
                    {{ order_allinfo.hotel.mobile }}
                  </div>
                </div> -->
                  <div class="title_text">
                    <div>酒店地址:</div>
                    <div style="font-size: 12px">
                      {{ order_allinfo.get_address }}
                    </div>
                  </div>
                </div>
                <div class="title_order" v-if="order_allinfo.answer_staff_name">
                  应单详情
                </div>
                <div
                  class="title_conter"
                  v-if="order_allinfo.answer_staff_name"
                >
                  <div class="title_text">
                    <div>应单人员：</div>
                    <div style="font-size: 12px">
                      <!-- {{ order_allinfo.answer_staff_name }} -->
                    </div>
                  </div>
                  <div class="title_text">
                    <div>应单时间：</div>
                    <div style="font-size: 12px">
                      <!-- {{ order_allinfo.answer_time }} -->
                    </div>
                  </div>
                </div>
                <div class="title_order" v-if="order_allinfo.recv_staff_name">
                  送单详情
                </div>
                <div class="title_conter" v-if="order_allinfo.recv_staff_name">
                  <div class="title_text">
                    <div>送单人员：</div>
                    <div style="font-size: 12px">
                      <!-- {{ order_allinfo.recv_staff_name }} -->
                    </div>
                  </div>
                  <div class="title_text">
                    <div>送单时间：</div>
                    <div style="font-size: 12px">
                      <!-- {{ order_allinfo.recv_time }} -->
                    </div>
                  </div>
                </div>
              </div>
            </van-collapse>
            <div class="dialog_button" v-if="login == 'hotel_count'">
              <dv-border-box-12
                class="dialog_button_buts"
                @click="createownlinenwashdetail"
                >确认洗涤</dv-border-box-12
              >
            </div>
            <div class="dialog_button" v-if="login == 'factory_count'">
              <dv-border-box-12
                class="dialog_button_buts"
                @click="factorycreateorder"
                >确认洗涤</dv-border-box-12
              >
            </div>
          </dv-border-box-6>
        </van-col>
      </van-row>
    </dv-border-box-8>
  </dv-full-screen-container>
</template>
<script>
import {
  Col,
  Row,
  Tab,
  Tabs,
  Icon,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  Button,
  Card,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Notify,
  Overlay,
  Loading,
  CountDown,
  Circle,
  Toast,
  Progress,
  Checkbox,
  CheckboxGroup,
  Field,
  Dialog,
  Collapse,
  CollapseItem,
  Radio,
  RadioGroup,
} from "vant";
import api from "../utils/api";
import countTo from "./vue-countTo.vue";
import { ref, reactive } from "vue";
import mqtt from "mqtt";
import store from "../store/index";
import _ from "lodash";
export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Card.name]: Card,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [CountDown.name]: CountDown,
    [Circle.name]: Circle,
    [Toast.name]: Toast,
    [Progress.name]: Progress,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    countTo,
  },
  setup() {
    const num = store.state.edition;
    const state = reactive({
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    });
    const onLoad = () => {
      if (state.refreshing) {
        state.list = [];
        state.refreshing = false;
      }
      state.finished = true;
    };
    const onRefresh = () => {
      // 清空列表数据
      state.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      state.loading = true;
    };
    const activeNames = ref("1");
    const show = ref(false);
    const wash_show = () => {
      show.value = true;
    };
    const errorHandler = (error, vm) => {
      console.error("抛出全局异常");
      console.error(vm);
      console.error(error);
    };
    const duka = ref({
      text: "已断开连接",
      color: "color:red",
    });
    return {
      loading: ref(false),
      duka,
      num,
      errorHandler,
      show,
      showorder: ref(false),
      showorders: ref(),
      wash_show,
      state,
      onLoad,
      onRefresh,
      activeNames,
      listheight: ref(""),
      lineNumber: 3,
      qrcode: ref(""),
      time: 2000,
      backtime: 60000,
      intervalId: ref(null),
      intervalIds: ref(null),
      login_token: ref(""),
      login_data: ref({}),
      login: ref(false),
      mtopic: ref(""),
      mtopic_order: ref(""),
      mtopicheart: ref(""),
      dur: ref(0),
      order: ref({}),
      bucao_summary: ref([]),
      rooms: ref([]),
      scene: ref("hotel"),
      device_id: ref(""),
      device: ref({}),
      thumb: ref(""),
      bucao_count: ref(0),
      read_count: ref(0),
      uncount: ref(0),
      msg: ref({}),
      service: ref(""),
      staff_code: ref(""),
      clientId: ref(""),
      status_msg: ref(""),
      status_msgs: ref([
        {
          msg: "设备获取",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面智慧工作站设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面读卡器设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面票据打印机设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "云端连接",
          status: false,
          warn: false,
        },
        {
          msg: "service云端订阅",
          status: false,
          warn: false,
        },
      ]),
      status: ref(false),
      networks: ref(false),
      network_time: 5000,
      network_Id: null,
      staff_info: ref({}),
      order_code: ref([]),
      washs: ref({}),
      wash: ref([]),
      color: ref("color:#FFD900"),
      orders: ref([]),
      verify_count: ref([]),
      hotels: ref([]),
      customer_list: ref([]),
      hotel_sense: ref(""),
      hotel_count: ref(),
      order_sense: ref(""),
      order_count: ref(),
      device_type: ref(""),
      mycount: ref(0),
      hotel_allinfo: ref({}),
      order_allinfo: ref({}),
      lctf_device_code: ref(""),
      throttle: ref(),
      pauses: ref(false),
      resetnum: ref(0),
      restartsnum: ref(0),
      restartsts: ref(),
      resetnumts: ref(),
      owncount: ref(0),
      ownshow: ref(false),
      ownhotel: ref({}),
      msgcount: ref(0),
      bucao_type: ref(""),
      hotel: ref({}),
      order_: ref(""),
    };
  },
  mounted() {
    document.title = "芯任收污点数";
    window.restart = this.restart;
    window.deskservice = this.deskservice;
    // this.throttle = _.throttle(function () {
    //   this.washdetectorderwash();
    // }, 2000);
    this.network();
    this.network_refreh();
    this.listheight = 3.8 + "rem";
    // this.apptonelist()
    this.devicefetch(this.$route.query.device_code);
    this.clientId =
      this.$route.query.device_code + "_" + Math.floor(Math.random() * 10 + 1);
    console.log(this.clientId);
    if (this.$route.query.order) {
      this.login = true;
      this.order_code = this.$route.query.order;
      let order = [];
      order.push(this.$route.query.order);
      // this.washorderdetails(order);
      this.clear();
    }
  },
  watch: {
    msg: function (newVal, endVal) {
      // console.log(newVal, endVal);
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal.count;
          // if (newVal.records) {
          //   newVal = newVal.records.map((i, e) => {
          //     i.uncount = endVal.records[e].count;
          //   });
          // }
        } else {
          this.uncount == 0;
          // newVal = newVal.records.map((i) => {
          //   i.uncount = 0;
          // });
        }
      }
    },
    read_count: function (newVal, endVal) {
      if (endVal && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal;
        } else {
          this.uncount == 0;
        }
      }
    },
  },
  methods: {
    _isMobile(e) {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag == null) {
        console.log("==>PC端");
      } else {
        console.log("==>移动端");
        window.Android.playTone(e);
      }
    },
    restart() {
      this.login = false;
    },
    restarts() {
      if (this.restartsts) {
        if (
          new Date().getTime() - this.restartsts < 3000 &&
          this.restartsnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.restartsnum = 0;
        } else {
          this.restartsnum++;
        }
        if (new Date().getTime() - this.restartsts > 3000) {
          this.restartsnum = 0;
        }
      }
      this.restartsts = new Date().getTime();
      this.login = false;
    },
    _throttle() {
      this.throttle;
    },
    owncancel() {
      this.ownshow = false;
      this.owncount = 0;
    },
    ownok() {
      this.ownshow = false;
      console.log("order", this.order_allinfo);
      if (this.order_allinfo && this.order_allinfo.code) {
        if (this.order_leaser_list) {
          if (this.order_leaser_list.length <= 1) {
            this.login = "hotel_count";
          } else {
            this.showorder = true;
          }
        }
        return;
      }
      this.login = "hotel_count";
    },
    apptonelist() {
      const data = {
        app_name: "hasn",
      };
      api.apptonelist(data).then((res) => {
        console.log("语音", res?.data?.data);
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    deskfetchreadingservice() {
      api
        .deskfetchreadingservice({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次", res.data);
          if (res.data.errcode == 0 && res.data.data.service) {
            this.service = res.data.data.service;
            // this.throttlewash();
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    // 检查网络连接
    network() {
      if (window.navigator.onLine == true) {
        let img = new Image();
        img.src =
          "http://fdfs.singran.vip/group1/M00/39/36/rBEAA2DAhiaAcZK4AADaqwfWS6s275.png";
        img.onload = () => {
          console.log("网络已连接");
        };
        img.onerror = () => {
          console.log("无网络连接，请检查网络");
          this.status_msg = "无网络连接，请检查网络";
          if (this.status_msgs) {
            this.status_msgs.map((i) => {
              i.status = false;
              i.warn = false;
            });
          }
          Dialog.alert({
            title: "网络已断开连接",
            message: "请检查网络",
          }).then(() => {});
          this._isMobile("cut_off_internet");
        };
      } else {
        console.log("网络未连接--请检查网络");
      }
    },
    // 定时器
    network_refreh() {
      if (this.network_Id) {
        clearInterval(this.network_Id);
      } else {
        console.log(`打开定时器${this.network_time}`);
        this.network_Id = setInterval(() => {
          this.network();
        }, this.network_time);
      }
    },
    mqtt() {
      console.log("mqtt");
      this.client = mqtt.connect("ws://mqtt.singran.vip:8083/mqtt", {
        clientId: this.clientId,
        clean: true,
        // reconnect: true,
        keepAliveInterval: 30,
        // reconnectInterval: 10,
        username: "xinren_factory",
        password: "xinren20200807",
      });
      // 断开发起重连
      // this.client.on("reconnect", (error) => {
      //   console.log("正在重连:", error);
      //   this.status_msg = "云端尝试重新连接中";
      //   this.status_msgs.push({ msg: "云端尝试重新连接中", status: false });
      // });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
        this.status_msg = "云端断开连接";
        Dialog.alert({
          title: "云端断开连接",
          message: "平板未连接上云端，请联系芯任人员",
        }).then(() => {
          // on close
        });
      });
      this.client.on("connect", (e) => {
        console.log("连接成功", e);
        setTimeout(() => {
          this.status_msg = "云端连接成功";
          this.status_msgs[4].status = true;
        }, 1000);
        this.client.subscribe(this.mtopic, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopic);
            setTimeout(() => {
              this.status_msg = "service云端订阅成功";
              this.status_msgs[5].status = true;
            }, 1200);
          } else {
            setTimeout(() => {
              this.status_msg = "service云端订阅失败";
              this._isMobile("connect_cloud");
            }, 1200);
          }
          setTimeout(() => {
            this.status_msg = "等待读卡中...";
          }, 1300);
        });
        this.client.subscribe(this.mtopicheart, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopicheart);
          }
        });
        this.client.on("message", (topic, message) => {
          this.msg = JSON.parse(String(message));
          if (topic.indexOf("service") != -1) {
            console.log("读卡推送", this.msg);
            if (this.msg.status == 0) {
              console.log("进行中 ===>");
              this.dur = 3;
              this.clears();
              if (this.msg.size_records.length > 9) {
                this.lineNumber = 4;
              }
              this.read_count = this.msg.count;
              this.service = this.msg.service;
              this.bucao_summary = this.msg.size_records;
              // this.throttle();
              this.msgcount++;
              if (this.msgcount == 1) {
                this.washdetectorderwash();
              }
            } else {
              console.log("已关闭 ===>");
              this.sum = 0;
              this.dur = 0;
              this.read_count = 0;
              this.lineNumber = 3;
              this.show = false;
              this.showorder = false;
              this.hotel_count = null;
              this.order_count = null;
              this.order_sense = null;
              this.msg = {};
              this.service = "";
              this.bucao_summary.map((i) => {
                i.match_count = 0;
                i.count = 0;
                i.color = "color:#40C5FF";
              });
              this.hotels = [];
              this.mycount = 0;
              this.restart();
              this.msgcount = 0;
              this.owncount = 0;
            }
          }
          if (topic.indexOf("heart") != -1) {
            console.log("心跳", this.msg);
            if (this.msg.connect == 0) {
              this.duka = {
                text: "已断开连接",
                color: "color:red",
              };
              Dialog.alert({
                title: "读卡设备已断开网络，请检查网络",
                message: "请检查网络",
              }).then(() => {});
            } else {
              this.duka = {
                text: "网络已连接",
                color: "color:green",
              };
            }
          }
        });
      });
    },
    readerstatus() {
      api
        .readerstatus({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次心跳", res);
          if (res.data.data.status == 1) {
            this.duka = {
              text: "网络已连接",
              color: "color:green",
            };
          } else {
            this.duka = {
              text: "已断开连接",
              color: "color:red",
            };
            Dialog.alert({
              title: "读卡设备已断开网络，请检查网络",
              message: "请检查网络",
            }).then(() => {});
          }
        });
    },
    leaserfindcustomer(e) {
      api.leaserfindcustomer({ leaser_code: e }).then((res) => {
        console.log("客户", res.data);
        if (res.data.errcode != 0) {
          Notify({
            message: res.data.errmsg,
            duration: 3000,
            type: "danger",
          });
          return;
        }
        this.customer_list = res.data.customer_list;
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    // 是否有关联订单
    washdetectorderwash() {
      api
        .washdetectorderwash({
          service: this.service,
          device_type: this.device_type,
        })
        .then((res) => {
          console.log("是否有关联订单", res.data);
          if (res.data.errcode == 0) {
            console.log(
              "this.device.device.leaser_code",
              this.device.device.leaser_code
            );
            this.bucao_type = res.data.data.bucao_type;
            this.hotel = res.data.data.hotel;
            // 租赁布草
            if (
              res.data.data.bucao_type == "lease" &&
              this.$route.query.staff_type == "leaser"
            ) {
              this.hotels = res.data.data.hotels;
              console.log("所有的酒店信息布草：", this.hotels);
              if (!res.data.data.order_wash) {
                console.log("工厂计数", this.msg.size_records, this.login);
                this.bucao_summary = this.msg.size_records;

                if (this.hotels.length == 1 && this.login == false) {
                  this.mycount++;
                  if (this.mycount == 1) {
                    this.leaserfindcustomer(res.data.data.leaser_code);
                    this.show = true;
                  }
                } else if (this.hotels.length > 1 && this.login == false) {
                  this.mycount++;
                  if (this.mycount == 1) {
                    this.leaserfindcustomer(res.data.data.leaser_code);
                    this.show = true;
                  }
                }
                console.log("show", this.show);
              }
              if (
                res.data.data.order_wash &&
                res.data.data.order_wash.length > 0
              ) {
                console.log("酒店计数", this.msg.records);
                if (
                  res.data.data.order_wash.length == 1 &&
                  this.login == false
                ) {
                  this.mycount++;
                  if (this.mycount == 1) {
                    this.leaserfindcustomer(res.data.data.leaser_code);
                    this.show = true;
                  }
                } else if (
                  res.data.data.order_wash.length > 1 &&
                  this.login == false
                ) {
                  this.mycount++;
                  if (this.mycount == 1) {
                    this.leaserfindcustomer(res.data.data.leaser_code);
                    this.show = true;
                  }
                }
              }
            } else if (
              res.data.data.bucao_type == "own" &&
              this.$route.query.staff_type == "factory"
            ) {
              console.log("自有布草");
              if (res.data.data.hotel) {
                this.ownhotel = res.data.data.hotel;
                this.owncount++;
                if (this.owncount == 1) {
                  this.ownshow = true;
                  console.log(this.owncount);
                }
                if (res.data.data.order_wash) {
                  this.order_allinfo = res.data.data.order_wash[0];
                  this.order_leaser_list = res.data.data.order_wash;
                }
              }else{
                this.showorder = true
                this.order_allinfo = res.data.data.order_wash[0];
                this.order_leaser_list = res.data.data.order_wash;
              }
            } else if (res.data.data.bucao_type == "mix") {
              console.log("混合布草");
              Notify({
                type: "danger",
                duration: 3000,
                message: "不能同时不同类型布草,请分拣后再读卡",
              });
            }
            if (
              res.data.data.bucao_type == "lease" &&
              this.$route.query.staff_type == "factory"
            ) {
              Notify({
                type: "danger",
                duration: 3000,
                message: "当前布草为租赁布草，请使用租赁身份登录",
              });
            }
            if (
              res.data.data.bucao_type == "own" &&
              this.$route.query.staff_type == "leaser"
            ) {
              Notify({
                type: "danger",
                duration: 3000,
                message: "当前布草为自有布草，请使用工厂身份登录",
              });
            }
          }
        });
    },
    washdifforderwash() {
      api
        .washdifforderwash({
          orders: this.order_code,
          service: this.service,
          device_type: this.device_type,
        })
        .then((res) => {
          console.log("wash", res.data);
          if (res.data.errcode == 0) {
            this.washs = res.data.data;
            this.wash = res.data.data.summary;
            if (res.data.data.read_count > res.data.data.order_code) {
              this.color = "color:red";
            }
            console.log(this.order);
            let order = this.order;
            order.map((e, i) => {
              e.verify_count = res.data.data.verify_count[i].order_verify_count;
            });
            this.order = order;
            this.bucao_summary = this.wash.map((i) => {
              if (i.read_count > i.match_count) {
                return {
                  code: i.code,
                  name: i.name,
                  sort: i.sort,
                  count: i.order_count,
                  match_count: i.match_count,
                  read_count: i.read_count,
                  color: "color:red",
                };
              } else {
                return {
                  code: i.code,
                  name: i.name,
                  sort: i.sort,
                  count: i.order_count,
                  match_count: i.match_count,
                  read_count: i.read_count,
                  color: "color:#40C5FF",
                };
              }
            });
            console.log("this.bucao_summary", this.bucao_summary);
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    throttlewash: _.throttle(function () {
      this.washdifforderwash();
    }, 500),
    staffgetstaffinfo() {
      let type;
      if (this.device.device?.hotel_code) {
        type = "hotel";
      }
      if (this.device.device?.factory_code) {
        type = "factory";
      }
      if (this.device.device?.leaser_code) {
        type = "leaser";
      }
      api
        .staffgetstaffinfo({
          staff_code: this.$route.query.staff_code,
          staff_type: this.$route.query.staff_type || type,
        })
        .then((res) => {
          console.log("staff", res.data);
          if (res.data.errcode == 0) {
            res.data.data.staff_info.avatar =
              "http://api.singran.vip/staffavatar/" +
              res.data.data.staff_info.code;
            this.staff_info = res.data.data.staff_info;
            res.data.data.staff_info.role = res.data.data.staff_info.duty;
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    createownlinenwashdetail() {
      console.log("order", this.order_allinfo.code, this.order_);
      if (this.order_allinfo.code || this.order_) {
        api
          .createownlinenwashdetail({
            service: this.service,
            order: this.order_allinfo.code || this.order_,
            staff_code: this.$route.query.staff_code,
            staff_type: this.$route.query.staff_type,
            app_code: this.$route.query.app_id,
            app_scene: this.$route.query.app_scene,
            device_type: this.device_type,
            device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
          })
          .then((res) => {
            console.log("确认下单", res);
            if (res.data.errcode != 0) {
              Notify({
                message: res.data.errmsg,
                duration: 3000,
                type: "danger",
              });
              return;
            }
            Toast.success({
              message: "操作成功！",
            });
            setTimeout(() => {
              this.restart();
            }, 1000);
          });
      } else {
        this.createownlinenwash();
      }
    },
    createownlinenwash() {
      api
        .createownlinenwash({
          hotel_code: this.hotel.code,
          staff_code: this.$route.query.staff_code,
          staff_type: this.$route.query.staff_type,
          app_code: this.$route.query.app_id,
          app_scene: this.$route.query.app_scene,
        })
        .then((res) => {
          console.log("确认下单", res);
          if (res.data.errcode != 0) {
            Notify({
              message: res.data.errmsg,
              duration: 3000,
              type: "danger",
            });
            return;
          }
          this.order_ = res.data.data.order;
          this.createownlinenwashdetail();
          // Toast.success({
          //   message: "操作成功！",
          // });
          // setTimeout(() => {
          //   this.restart();
          // }, 1000);
        });
    },
    debouncewash: _.debounce(function () {
      this.createownlinenwashdetail();
    }, 3000),
    stress() {
      this.sum = 0;
      console.log("重读");
      this.clears();
      this.msg = {};
      this.uncount = 0;
      this.deskservice("1");
      setTimeout(() => {
        this.restart();
      }, 1000);
    },
    pause() {
      console.log("暂停");
      this.deskservice("2");
      this.pauses = true;
    },
    goon() {
      console.log("继续");
      this.deskservice("3");
      this.pauses = false;
    },
    zero() {
      this.sum = 0;
      this.clears();
      this.msg = {};
      this.uncount = 0;
      if (this.login == "factory_logarithm") {
        this.order.map((e) => {
          e.verify_count = 0;
        });
      }
      this.deskservice("1");
    },
    deskservice(e) {
      this.clears();
      api
        .servicecontrol({
          code: this.service,
          status: e,
          device_type: this.device_type,
        })
        .then((res) => {
          console.log("操作", e, this.service, res);
          if (res.data.errcode == 0 && e == 1) {
            Notify({ message: "已关闭", duration: 1000, type: "danger" });
          } else if (res.data.errcode == 0 && e == 2) {
            Notify({ message: "已暂停", duration: 1000, type: "warning" });
          } else if (res.data.errcode == 0 && e == 3) {
            Notify({ message: "已继续", duration: 1000, type: "success" });
          }
        });
    },
    //切换app的bug
    resetdevice() {
      if (this.resetnumts) {
        if (
          new Date().getTime() - this.resetnumts < 3000 &&
          this.resetnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.resetnum = 0;
        } else {
          this.resetnum++;
        }
        if (new Date().getTime() - this.resetnumts > 3000) {
          this.resetnum = 0;
        }
      }
      this.resetnumts = new Date().getTime();
      this.loading = true;
      api
        .resetdevice({
          device_code: this.lctf_device_code,
          cmd: "1",
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            this.loading = res.loading;
            Notify({
              message: "已发送重启命令",
              duration: 1000,
              type: "success",
            });
          }
          //  else {
          //   Notify({ message: "重置读卡失败", duration: 1000, type: "danger" });
          // }
        });
      // .catch((err) => {
      //   console.log(err);
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    // 检查设备归属
    devicefetch(e) {
      api
        .devicefetch({
          device_code: e,
        })
        .then((res) => {
          console.log("设备", res.data);
          if (res.data.errcode != 0) {
            Dialog.alert({
              title: res.data.errmsg,
              message: "请检查平板归属与设备类型",
            }).then(() => {
              // on confirm
            });
            this.status_msg = "设备获取失败";
            this.status_msgs[0].status = false;
            return;
          }
          this.status_msg = "设备获取成功";
          this.status_msgs[0].status = true;
          if (res.data.data.device.category == "dpod") {
            setTimeout(() => {
              if (res.data.data.device.code) {
                this.status_msg = res.data.data.device.name + "设备配置成功";
                this.status_msgs[1].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.device.lctf) {
                this.status_msg =
                  res.data.data.device.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.device.prnt) {
                this.status_msg =
                  res.data.data.device.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status = false;
                return;
              }
            }, 600);
          } else if (res.data.data.device.category == "lctf") {
            setTimeout(() => {
              if (res.data.data.dpod.code) {
                this.status_msg = res.data.data.dpod.name + "设备配置成功";
                this.status_msgs[1].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.dpod.lctf) {
                this.status_msg = res.data.data.dpod.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.dpod.prnt) {
                this.status_msg = res.data.data.dpod.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status = false;
                return;
              }
            }, 600);
          }
          console.log("123");
          if (res.data.data.device.category == "dpod") {
            this.device = res.data.data;
            this.device_id = res.data.data.device.id;
            this.lctf_device_code = res.data.data.lctf.code;
            this.thumb = res.data.data.equipment.thumb;
            this.device_type = res.data.data.lctf_equipment.rfid_mode;
            console.log("this.dpod.device_type", this.device_type);
            this.staffgetstaffinfo();
            // if (!this.device.device.hotel_code) {
            //   this.leaserfindcustomer();
            // }
          } else if (res.data.data.device.category == "lctf") {
            this.device = res.data.data;
            // this.device_id = res.data.data.device.id;
            this.device_id = res.data.data.dpod.id;
            this.lctf_device_code = res.data.data.device.code;
            this.thumb = res.data.data.dpod_equipment.thumb;
            this.device_type = res.data.data.equipment.rfid_mode;
            console.log("this.lctf.device_type", this.device_type);
            this.staffgetstaffinfo();
            // if (!this.device.device.hotel_code) {
            //   this.leaserfindcustomer();
            // }
          }
          this.readerstatus();
          this.mtopic =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/service";
          this.mtopicheart =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/heart";
          this.mqtt();
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    hotelcreateorder() {
      api
        .hotelcreateorder({
          device_type: "box",
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
          service: this.service,
          staff_code: this.$route.query.staff_code,
          app_scene: this.$route.query.app_id,
          app_code: this.$route.query.app_scene,
        })
        .then((res) => {
          console.log("酒店下单", res.data);
          if (res.data.errcode != 0) {
            Dialog.alert({
              title: res.data.errmsg,
            }).then(() => {
              // on close
            });
            return;
          }
          Toast.success({
            message: "操作成功！",
          });
          setTimeout(() => {
            this.restart();
          }, 500);
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    factorycreateorder() {
      let hotel_code;
      if (this.device.device.factory_code) {
        hotel_code = this.hotels[0].code;
      } else if (this.device.device.leaser_code) {
        hotel_code = this.hotel_sense;
      }
      let data;
      if (this.order_sense) {
        data = {
          staff_code: this.$route.query.staff_code,
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
          device_type: this.device_type,
          hotel_code: hotel_code,
          order: this.order_sense,
          service: this.service,
          app_code: this.$route.query.app_id,
          app_scene: this.$route.query.app_scene,
          type: this.bucao_type,
        };
      } else {
        data = {
          staff_code: this.$route.query.staff_code,
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
          device_type: this.device_type,
          hotel_code: hotel_code,
          service: this.service,
          app_code: this.$route.query.app_id,
          app_scene: this.$route.query.app_scene,
          type: this.bucao_type,
        };
      }
      api.factorycreateorder(data).then((res) => {
        console.log("工厂计数下单", res);
        if (res.data.errcode != 0) {
          Dialog.alert({
            title: res.data.errmsg,
          }).then(() => {
            // on close
          });
          return;
        }
        Toast.success({
          message: res.data.data.message,
          duration: 3000,
        });
        setTimeout(() => {
          this.restart();
        }, 500);
      });
    },
    washorderdetails(e) {
      api.washorderdetails({ orders: e }).then((res) => {
        console.log("order", res.data);
        if (res.data.errcode != 0) {
          Notify({
            message: res.data.errmsg,
            duration: 1000,
          });
          return;
        }
        res.data.data.orders.map((i) => {
          if (i.scene == "bandb") {
            if (!i.print_count) {
              i.print_info = "未打印";
            } else if (i.print_count < 0) {
              i.print_info = "重打印";
            } else {
              i.print_info = i.time;
            }
          } else {
            i.print_info = "";
          }
          i.minute = i.minute.split(" ")[1];
        });
        this.order = res.data.data.orders;
        this.bucao_count = res.data.data.bucao_count;
        this.sum = res.data.data.bucao_count;
        this.bucao_summary = res.data.data.bucao_summary;
        // this.throttlewash();
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    washrelationwash() {
      api
        .leaserrelationleaserorder({ hotel_code: this.hotel_sense })
        .then((res) => {
          console.log("是否有订单", res);
          if (res.data.order_leaser_list.length > 0) {
            this.order_leaser_list = res.data.order_leaser_list;
            this.showorder = true;
            this.bucao_summary = this.msg.size_records;
            const order = res.data.order_leaser_list.map((e) => {
              return e.code;
            });
            this.order_code = order;
          } else {
            this.login = "factory_count";
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    sense(e, i) {
      console.log("点击选择的酒店", e, i);
      this.hotel_sense = e.hotel_code;
      this.hotel_count = i;
      this.hotel_allinfo = e;
    },
    submit() {
      this.show = false;
      this.clear();
      this.washrelationwash();
      // this.login = "factory_count";
    },
    senses(e, i) {
      console.log("点击选择的订单", e, i);
      this.order_sense = e.code;
      this.order_count = i;
      this.order_allinfo = e;
      // this.order = e
    },
    jump() {
      this.show = false;
      this.showorder = false;
      this.clear();
      this.login = "factory_count";
    },
    submits() {
      console.log("bucao_type", this.bucao_type);
      this.show = false;
      this.showorder = false;
      this.clear();

      if (this.bucao_type == "lease") {
        this.login = "factory_count";
      }
      if (this.bucao_type == "own") {
        this.login = "hotel_count";
      }
    },
    // 停止定时器
    clear() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    clears() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalIds); //清除计时器
      this.intervalIds = null; //设置为null
    },
    getBorderClass(index) {
      index += 1;
      let className = "";
      let lineNumber = this.lineNumber; // 每行行显示个数
      let total = this.bucao_summary.length; // 总个数
      let line = Math.ceil(total / lineNumber); // 一共有几行
      let currentLine = 0; // 当前在第几行,这里从1开始
      let currentIndex = 0; // 当前在某行的第几个
      currentLine = Math.ceil(index / lineNumber);
      currentIndex = index % lineNumber === 0 ? lineNumber : index % lineNumber;
      // 右下边框
      if (currentLine < line && currentIndex < lineNumber) {
        className = "border-bottom border-right";
      }
      // 右边框
      if (currentLine === line && currentIndex < lineNumber) {
        className = "border-right";
      }
      // 下边框
      if (currentLine < line && currentIndex === lineNumber) {
        className = "border-bottom";
      }
      // 无边框
      if (currentLine === line && currentIndex === lineNumber) {
        className = "";
      }
      return className;
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.van-collapse-item__content {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell {
  background: rgba(0, 0, 0, 0);
  padding: 3px;
}
/deep/.van-cell__title,
.van-cell__value {
  flex: none;
}
/deep/.van-popup {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell-group {
  background: rgba(0, 0, 0, 0);
}
.bj {
  background-image: url("../assets/bj.jpg");
}
.logo {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  margin-right: 3px;
}
.logos {
  width: 20px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
}
.info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  height: 10px;
  margin-bottom: 2px;
}
.infotime {
  font-size: 12px;
  color: #fff;
  text-align: left;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.heard {
  height: 16px;
  width: 16px;
}
.device {
  color: #fff;
  font-size: 10px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.border {
  border-radius: 5px;
  padding: 3px;
}
.app-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(12, 24, 51, 0);
}
.app-card-content_item {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 4px;
}
.fenlei {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  font-size: 30px;
  color: #40c5ff;
}
.box_name {
  font-size: 18px;
  color: #4a9cbd;
}
.box_name_size {
  font-size: 14px;
  color: #2e718b;
}
.btn {
  padding-top: 5px;
}
.qrcode {
  width: 150px;
  height: 150px;
  border: 1px solid black;
}
.heardtext {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.zero {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  font-size: 50px;
  color: #ffd900;
}
.dialog_button {
  display: flex;
  justify-content: space-around;
}
.dialog_button_but {
  height: 50px;
  margin-top: 5px;
  width: 30%;
  font-size: 20px;
  line-height: 50px;
  color: #40c5ff;
}
.dialog_button_buts {
  height: 40px;
  margin-top: 45px;
  width: 45%;
  font-size: 16px;
  line-height: 40px;
  color: #40c5ff;
  bottom: 30px;
  width: 100px;
}
.order_title {
  font-size: 16px;
  color: #3fc0fa;
}
.rooms_img {
  width: 70px;
  height: 45px;
  margin-left: 10px;
}
.order_center {
  color: #fff;
  font-size: 10px;
  overflow-y: scroll;
}
.order_center::-webkit-scrollbar {
  display: none;
}
.center_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 1px 0px 1px;
  width: 100%;
}
.title_order {
  font-size: 9px;
  color: #3fc0fa;
}
.title_conter {
  padding: 1px 5px;
  width: 100%;
  font-size: 7px;
}
.title_text {
  margin: 1px 0;
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #fff;
}
.overflow {
  overflow-y: scroll;
}
.overflow::-webkit-scrollbar {
  display: none;
}
.preview {
  font-size: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.status_msg {
  border-top: 1px solid rgb(255, 255, 255);
  padding: 2px;
  text-align: left;
}
.status {
  width: 20%;
  margin: 10px auto;
  height: 150px;
  overflow-y: scroll;
}
.status::-webkit-scrollbar {
  display: none;
}
.collapse {
  height: 300px;
  overflow-y: scroll;
}
.collapse::-webkit-scrollbar {
  display: none;
}
.button {
  width: 80px;
  height: 40px;
  position: fixed;
  right: 120px;
  bottom: 10px;
}
.buttonS {
  width: 100px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.popup {
  width: 300px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0);
}
.height {
  height: 200px;
  overflow-y: scroll;
}
.height::-webkit-scrollbar {
  display: none;
}
.boxdiv {
  position: absolute;
  background: rgba(22, 39, 78);
  bottom: 20%;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
}
.duka {
  font-size: 8px;
}
</style>