<template>
  <Boxwash />
</template>

<script>
import Boxwash from "../../../components/factory_boxwash";

export default {
  name: "factory_boxcheck",
  components: {
    Boxwash,
  },
};
</script>